import React from 'react'
import './Contact.css'
import ContactImage from '../../assest/contact.jpg'

const Contact = () => {
  function sendEmail(){
       sendEmail.send({
      Host : "smtp.gmail.com",
      Username : "srvinith6@gmail.com",
      Password : "vinith2624",
      To : 'srvinith6@gmail.com',
      From : document.getElementById('email').value,
      Subject : "New job Search",
      Body : "And this is the body"
  }).then(
    message => alert(message)
  );
  }
  
  return (
    <div className='contact' id='contact'>
        <h1>Contact</h1>
        <div className="contact-container">
        <div className="contact-left">
            <img src={ContactImage} alt=''/>
        </div>
        <div className="contact-right">
           <form onsubmit ="sendEmail(); reset(); retrun false;">
           <label>Name</label>
            <input type="name" id="name" name='name' required />
            <label>Email</label>
            <input type="email" id="email" name='user-email' required />
            <label>Messsage</label>
            <textarea name="message" id="message" cols="5" rows="3" placeholder='Message'> </textarea>
            <button type='submit'>Send</button>
           </form>
        </div>
        </div>
        
        
    </div>
  )
}

export default Contact