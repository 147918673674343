import React from 'react'
import './Footer.css'

const footer = () => {
  return (
    <div className='footer' id='footer'>
        <hr/>
        <div className="footer-container">
            <div className="blur-container">
                <div className="blur-footer-l"></div>
                <div className="blur-footer-r"></div>
            </div>
            <ul>
             
            <li><a href="https://www.facebook.com/vinithrajangam.vinithrajangam"> 
            <i class="fa-brands fa-square-facebook"></i>Faceebook</a></li>

            
            <li><a href="https://www.instagram.com/invites/contact/?i=1wc3ee9sxpxc6&utm_content=276lkr5">
                <i class="fa-brands fa-square-instagram"></i>Instagram</a></li>
            
            
            <li><a href="https://github.com/SRVINITH/rayar_bike">
                <i class="fa-brands fa-git-alt"></i>Github</a></li>
            </ul>
        </div>
        <div className="link">
            <div className="link-container">
            <a href=""><i class="fa-solid fa-envelope"></i>srvinith6@gmail.com</a>
            <a href=""><i class="fa-solid fa-phone"></i>98655 97859</a>
            </div>
        
        </div>
        </div>
  )
}

export default footer