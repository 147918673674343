import React from 'react'
import './Project.css'
import  {ProjectData} from './ProjectData'


const Project = () => {
  return (
    
    <div className='project' id='project'>
    
        <h1>Project</h1>
        <div className="project-container">
          <div className="blur-continaer">
            <div className="blur-left"></div>
            <div className="blur-right"></div>
          </div>

        {ProjectData.map((Proj ,i)=>(
        <div className="proj" key={i}>

            <img src={Proj.Image} alt="" />
            
            <div className ="proj-content">
            <span>{Proj.Title}</span>
            <span>Desc:{Proj.Desc}</span>
            <a href={Proj.link} download={Proj.link}>Download</a>
            
        
          </div>
        
        </div>
     ))}
          
        </div>
        
        
    </div>
  )
}

export default Project