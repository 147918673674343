import React from 'react'
import './Education.css'
import { EducationData} from './data'

const Education = () => {
  return (
    <div className='education' id='education'>
      
    <h2>Education</h2>
    <div className="box">
    <div className="blur-continaer">
            <div className="blur-left"></div>
            <div className="blur-right"></div>
          </div>
     {EducationData.map((plan ,i)=>(
        <div className="plan" key={i}>

           <span >{plan.Image}</span>
            <span>{plan.Id}</span>
            <span>Name:{plan.Name}</span>
            <span>Yera:{plan.Year}</span>
            <span>Precentage:{plan.Precentage}</span>
            <span>Address:{plan.Address}</span>
        </div>
     ))}
    </div>
    <div className="experince">
   
      <h4>Experince <span></span></h4>
      <div className="experince-content">
      <h5>Designation: <span>Team Leader</span></h5>
      <h5>Company Name: <span>Delhivery Pvt Ltd</span></h5>
      <h5>Year: <span>2020 - 2021</span></h5>
      <h5>Description : <span>Branch management and logistics B2B loading 
        checking team maintance and administration work</span></h5>
      </div>
    
    </div>

    </div>
  )
}

export default Education