
import './App.css';
import Hero from './components/hero/Hero'
import Skills from './components/Skills/Skills'
import Education from './components/education/Education'
import Project from './components/project/Project';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer'

function App() {
  return (
    <div className="App">
      <>
      <Hero/>
      <Skills/>
      <Education/>
      <Project/>
      <Contact/>
      <Footer />
      </>
    </div>
  );
}

export default App;
