
import ProjectOne from '../../assest/project-1.png'
import ProjectTwo from '../../assest/project-2.png'
import projectThree from '../../assest/project-3.jpg'
import Bike from './ProjectFile/bikes.rar'
import Psd from './ProjectFile/R .vinith.rar'
import Email from './ProjectFile/R .vinith.rar'






export const ProjectData= [
    {
        id:1,
        Image: ProjectOne,
        Title:'Bike Show Room',
        Skil: ['HTML', 'CSS', 'JS',],
        Desc: 'E-commerce Bike show room own concept bike specifiaction and model choose specilatity ' ,
        link : Bike
    },
    {
        id:2,
        Image: ProjectTwo,
        Title:'PSD file convert HTML Web page',
        Skil:['HTML', 'CSS' , 'JS','Photoshop'],
        Desc: 'Mountain Template convert HTML web page',
        link : Psd
      
    },
    {
        id:3,
        Image: projectThree,
        Title:'Email Template convert HTML Web page',
        Skil:['HTML', 'CSS' , 'JS','Photoshop'],
        Desc: 'Email Template convert HTML web page',
        link : Email
      
    }

];