import React from 'react'
import './Skills.css'
import Image from '../../assest/skill.jpg'

const Skills = () => {
  return (
    <div className='skills' id='skils'>
        <div className="skils-container">
        <div className="skill-left">
        <div className='blur-skill'></div>
        <img src= {Image}  alt =""/>
        </div>
        <div className="skill-right">
            <h2>Skills List</h2>
            <div className="skill-bar">
                <span>HTML</span>
                <div className="bar">
                    <div className="bar-value html">
                        <span>85%</span>
                    </div>
                </div>
            </div>
          
            <div className="skill-bar">
                <span>CSS</span>
                <div className="bar">
                    <div className="bar-value css">
                        <span >75%</span>
                    </div>
                </div>
            </div>

            <div className="skill-bar">
                <span>javascript</span>
                <div className="bar">
                    <div className="bar-value js">
                        <span>60%</span>
                    </div>
                </div>
            </div>

            <div className="skill-bar">
                <span>React js</span>
                <div className="bar">
                    <div className="bar-value react">
                        <span>55%</span>
                    </div>
                </div>
            </div>

            <div className="skill-bar">
                <span>Bootstrap</span>
                <div className="bar">
                    <div className="bar-value bootstraup">
                        <span>65%</span>
                    </div>
                </div>
            </div>

            <div className="skill-bar">
                <span>Photoshop</span>
                <div className="bar">
                    <div className="bar-value ps">
                        <span>85%</span>
                    </div>
                </div>
            </div>



        </div>
        </div>

        
        
        </div>
  )
}

export default Skills