import  SSLC from '../../assest/10th.jpg'
import HSC from '../../assest/12th.jpg'
import BNEC from '../../assest/bnec.jpg'
export const EducationData = [
    {   Image:<img src ={SSLC} alt=""/>,
         Id: "SSLC",
        Name:"Nadar Her Sec Scholl",
        Year :"2012-2014",
        Precentage :"69%",
        Address: "Bodinayakanur,Theni(Dt)."
    },
    {   Image:<img src ={HSC} alt=""/>,
        Id: "HSC",
        Name:"Govt Her Sec School",
        Year :"2014-2016",
        Precentage :"60%",
        Address:"Mc Puram,Bodinayakanur,Theni(Dt)."
    },
    {   Image:<img src ={BNEC} alt=""/>,
        Id: "BE (ECE)",
        Name:"Bharath Niketan Engineering College",
        Year :"2016-2020",
        Precentage :"64.8%",
        Address:"Aundipatty,Theni(Dt)."
    }
    
]