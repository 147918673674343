import React,{useState} from 'react'
import './Header.css'
import{Link} from 'react-scroll'
const Header = () => {
  const[Click, setClick]=useState(false);
  const handelClick =()=>setClick(!Click);
  return (
    <div className='header' id='header'>
      <div className="nav-head">
      <div className="nav-logo">
        <h1>V<span>inith</span></h1>
     </div>
    <div className="navbar">
        <ul className={Click?"nav-menu-r":"nav-menu"}>
            <li className="nav-list"><Link to='home' smooth='true' duration='500'>Home</Link></li>
            <li className="nav-list"><Link to='skills' smooth='true' duration='500'>Skils</Link></li>
            <li className="nav-list"><Link to='education' smooth='true' duration='500'>Education</Link></li>
            <li className="nav-list"><Link to='project' smooth='true' duration='500'>Project</Link></li>
            <li className="nav-list"><Link to='contact' smooth='true' duration='500'>Contact</Link></li>
            
        </ul>
        <div className="nav-icon" onClick={handelClick}>
        <i class={Click?"fas fa-times":"fas fa-bars"}></i>


        </div>
    </div>
      </div>

    </div>
  )
}

export default Header
