import React from 'react'
import Header from '../header/Header'
import './Hero.css'
import Resume from '../../resume/vinith.pdf'
import {Link} from 'react-scroll'
import Profile from '../../assest/profile.png' 

const hero = () => {
  return (
    <div className="home" id="home">
        <Header/>
        <div className="hero-container">
            <div className="hero-left">
              

                <div className="blur"></div>
                <div className="profile-box"></div>
            <div className="profile"></div>
            <div className="profile-img">
                  <img src={Profile} alt="" />  
            </div>

            </div>
            <div className="hero-right">
             <div className="hero-title">
             <h4>Hi I'm</h4>
              <h2>Vinith</h2>
              <div className="title-animation">
              <h3>Front end Developer</h3>
              </div>
              
             </div>
              <div className="btn">
                <a href={Resume} download={Resume}>Download Cv</a>
                <Link to='project' smooth='true' duration='500'>Works</Link>
                </div>          

            </div>
        </div>
    </div>
  )
}

export default hero